<template>
<div class="container">
    <div class="row justify-content-md-center mt-5">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        <p>Ref No:</p>
                        <input type="text" v-model="refNo" />
                        <button class="btn" @click.prevent="getPaymentByRefNo">
                            Get Payment Details
                        </button>
                        Basic implementation for the 2Pay.js client
                    </h5>

                    <form type="post" id="payment-form">
                        <div class="form-group">
                            <label for="name" class="label control-label">Name</label>
                            <input type="text" v-model="billingDetails.name" class="field form-control" />
                        </div>

                        <div id="card-element">
                            <!-- A TCO IFRAME will be inserted here. -->
                        </div>

                        <button class="btn btn-primary" :disabled="loading" @click.prevent="submit">
                            <span v-if="!loading">Pay Now</span>
                            <span v-else class="spinner-border spinner-border-lg"></span>
                            <span v-if="loading">Please wait...</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
let jsPaymentClient = new window.TwoPayClient("250538457371");

// Set the desired language to be used inside the iframe.
jsPaymentClient.setup.setLanguage("ro");
let component = jsPaymentClient.components.create("card");
import {
    myhttp
} from "@/data/myhttp";
export default {
    mounted() {
        component.mount("#card-element");
    },
    data: function () {
        return {
            billingDetails: {
                name: "Veeran Puthumkara",
            },
            loading: false,
            refNo: "",
        };
    },
    methods: {
        async submit() {
            let _this = this;
            try {
                _this.loading = true;
                let tokenResponse = await jsPaymentClient.tokens.generate(
                    component,
                    this.billingDetails
                );
                if (tokenResponse?.token) {
                    let tokenData = {
                        id: "1",
                        token: tokenResponse.token,
                    };
                    let response = await myhttp.post("twocheckout/checkout", tokenData);
                    _this.loading = false;
                    let data = response.data;
                    console.log(data);
                    if (data.responseType === 3) {
                        let ds = data.authorize3DS;
                        let url = ds.href + "?avng8apitoken=" + ds.params.avng8apitoken;
                        window.location = url;
                    }
                }
            } catch (error) {
                _this.loading = false;
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
            }
        },
        async getPaymentByRefNo() {
            let _this = this;
            try {
                _this.loading = true;
                let response = await myhttp("twocheckout/PaymentByRefNo/" + this.refNo);
                _this.loading = false;
                let data = response.data;
                console.log(data);
            } catch (error) {
                _this.loading = false;
                if (error.response) {
                    console.log(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
            }
        },
    },
};
</script>

<style></style>
